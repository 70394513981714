import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import { Typography } from '@mui/material';
import SideBar from './SideBar';
import { Sidebar2023 } from 'views/ScintillaOfArt2023/components';
import { Archive2022 } from 'views/ScintillaOfArt/components';


const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const SEWA= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
    
   
      <Container>
      <Typography variant='h4' align='center' fontWeight={700}>
           SEWA <br/>
        </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
              <Form />
            </Grid>
            <Grid item xs={12} md={3}>
             
                <Box marginBottom={4}>
                  <Sidebar2023 />
                </Box>

                <Box marginBottom={4}>
                  <Archive2022 />
                </Box>
             
              {/* <SidebarNewsletter /> */}
            </Grid>
          </Grid>
        </Container>
    </Main>
  );
};

export default SEWA;
